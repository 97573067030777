import { FormattedDate, FormattedMessage } from "react-intl";

import StyledTable from "components/StyledTable";
import type { Column } from "components/StyledTable";
import { Link, Route } from "Navigation";
import ConnectionStatus from "components/ConnectionStatus";
import Stack from "components/Stack";
import Tag from "components/Tag";
import "./AppliancesTable.scss";

type ApplianceProps = {
  id: string;
  name: string;
  assignee: { id: string; name: string } | null;
  serial: string;
  tags: string[];
  device: {
    lastConnection: string | null;
    lastDisconnection: string | null;
    online: boolean;
  };
};

const getColumnsDefinition = (
  hideAssignee: boolean
): Column<ApplianceProps>[] => [
  {
    id: "status",
    accessorFn: (appliance) => Boolean(appliance.device.online),
    header: () => (
      <FormattedMessage
        id="components.AppliancesTable.statusTitle"
        defaultMessage="Status"
        description="Title for the Status column of the appliances table"
      />
    ),
    cell: ({ getValue }) => {
      const value = getValue() as boolean;
      return <ConnectionStatus connected={value} />;
    },
    meta: {
      className: "align-middle",
    },
  },
  {
    accessorKey: "name",
    header: () => (
      <FormattedMessage
        id="components.AppliancesTable.nameTitle"
        defaultMessage="Appliance Name"
        description="Title for the Name column of the appliances table"
      />
    ),
    cell: ({ row, getValue }) => (
      <Stack gap={2}>
        <Link
          route={Route.appliancesEdit}
          params={{ applianceId: row.original.id }}
        >
          {getValue()}
        </Link>
        <small className="d-md-none">{row.original.serial}</small>
        {!hideAssignee && (
          <small className="d-md-none">{row.original.assignee?.name}</small>
        )}
      </Stack>
    ),
    meta: {
      className: "align-middle column-name",
    },
  },
  {
    accessorKey: "serial",
    header: () => (
      <FormattedMessage
        id="components.AppliancesTable.serialTitle"
        defaultMessage="Appliance S/N"
        description="Title for the Serial column of the appliances table"
      />
    ),
    meta: {
      className: "d-none d-md-table-cell",
    },
  },
  {
    id: "assignee",
    accessorFn: (appliance) => appliance.assignee?.name,
    header: () => (
      <FormattedMessage
        id="components.AppliancesTable.assigneeTitle"
        defaultMessage="Assignee"
        description="Title for the Assignee column of the appliances table"
      />
    ),
    meta: {
      className: "d-none d-md-table-cell",
    },
  },
  {
    id: "lastSeen",
    accessorFn: (appliance): string => {
      if (appliance.device.online) {
        return "now";
      } else {
        return appliance.device.lastDisconnection || "never";
      }
    },
    header: () => (
      <FormattedMessage
        id="components.AppliancesTable.lastSeenTitle"
        defaultMessage="Last Seen"
        description="Title for the Last Seen column of the appliances table"
      />
    ),
    cell: ({ getValue }) => {
      const value = getValue() as string;
      if (value === "now") {
        return (
          <FormattedMessage
            id="components.AppliancesTable.lastSeen.now"
            defaultMessage="Now"
            description="Label in the LastSeen column for an appliance that is online"
          />
        );
      } else if (value === "never") {
        return (
          <FormattedMessage
            id="components.AppliancesTable.lastSeen.never"
            defaultMessage="Never"
            description="Label in the LastSeen column for an appliance that never connected"
          />
        );
      } else {
        return (
          <FormattedDate
            value={new Date(value)}
            year="numeric"
            month="long"
            day="numeric"
            hour="numeric"
            minute="numeric"
          />
        );
      }
    },
    meta: {
      className: "d-none d-md-table-cell",
    },
  },
  {
    accessorKey: "tags",
    enableSorting: false,
    header: () => (
      <FormattedMessage
        id="components.AppliancesTable.tagsTitle"
        defaultMessage="Tags"
        description="Title for the Tags column of the appliances table"
      />
    ),
    cell: ({ getValue }) => {
      const value = getValue() as string[];
      return (
        <>
          {value.map((tag) => (
            <Tag key={tag} className="me-2">
              {tag}
            </Tag>
          ))}
        </>
      );
    },
    meta: {
      className: "align-middle",
    },
  },
];

interface Props {
  className?: string;
  data: ApplianceProps[];
  hideAssignee?: boolean;
  searchText?: string;
}

const AppliancesTable = ({
  className,
  data,
  hideAssignee = false,
  searchText,
}: Props) => {
  return (
    <StyledTable
      className={className}
      columns={getColumnsDefinition(hideAssignee)}
      data={data}
      hiddenColumns={hideAssignee ? ["assignee"] : []}
      searchText={searchText}
    />
  );
};

export type { ApplianceProps };

export default AppliancesTable;
